import { toUpper } from 'lodash';

const CRYPTO_DECIMAL_PLACES = 8;
const FIAT_DECIMAL_PLACES = 2;

const BNB = 'BNB';
const BTC = 'BTC';
const DASH = 'DASH';
const DGB = 'DGB';
const EGLD = 'EGLD';
const ETC = 'ETC';
const ETH = 'ETH';
const TRX = 'TRX';
const USDC = 'USDC';
const USDT = 'USDT';
const UTK = 'UTK';
const POLYGON = 'POLYGON';
const ERD = 'ERD';

export const supportedCrypto = [
  BNB,
  BTC,
  DASH,
  DGB,
  ETC,
  ETH,
  TRX,
  UTK,
  USDC,
  USDT,
];

const crypto = {
  [BNB]: 'binance coin',
  [BTC]: 'bitcoin',
  [DASH]: 'dash',
  [DGB]: 'digibyte',
  [EGLD]: 'egold',
  [ETC]: 'ethereum classic',
  [ETH]: 'ethereum',
  [TRX]: 'tron',
  [UTK]: 'utrust token',
  [USDC]: 'USD Coin',
  [USDT]: 'tether',
};

export const cryptoChains = {
  [ETH]: 'Ethereum',
  [POLYGON]: 'Polygon',
  [ERD]: 'MultiversX',
  [BTC]: 'Bitcoin',
  [DASH]: 'Dash',
};

export const fiat = {
  EUR: '€',
  GBP: '£',
  USD: '$',
  ARS: '$',
  AUD: '$',
  BRL: 'R$',
  CAD: '$',
  CLP: '$',
  CNY: '¥',
  CZK: 'Kč',
  DKK: 'kr',
  DOP: 'RD$',
  HKD: '$',
  HUF: 'Ft',
  INR: '₹',
  IDR: 'Rp',
  ILS: '₪',
  JPY: '¥',
  KRW: '₩',
  MYR: 'RM',
  MXN: '$',
  NZD: '$',
  NOK: 'kr',
  PKR: 'Rs',
  PHP: '₱',
  PLN: 'zł',
  RON: 'lei',
  RUB: '₽',
  SGD: '$',
  ZAR: 'R',
  SEK: 'kr',
  CHF: 'CHF',
  TWD: 'NT$',
  THB: '฿',
  TRL: '₺',
  AED: 'د.إ',
};

const referenceFiat = {
  EUR: '€',
  GBP: '£',
  USD: '$',
  RON: 'lei',
  CHF: 'CHF',
};

export const settlementFiat = {
  USD: `USD ${fiat.USD}`,
  EUR: `EUR ${fiat.EUR}`,
  GBP: `GBP ${fiat.GBP}`,
  RON: `RON ${fiat.RON}`,
  CHF: `CHF ${fiat.CHF}`,
};

const settlementCrypto = {
  [USDC]: [ETH],
};

export const settlementCurrenciesReadOnly = {
  [EGLD]: 'EGLD',
};

export function isCrypto(currency) {
  return Object.keys(crypto).includes(currency);
}

export function cryptoNames(ticker) {
  return crypto[toUpper(ticker)] || '';
}

export function fiatSymbols(ticker) {
  return fiat[toUpper(ticker)] || '';
}

const numberWithDecimals = (number, decimals) =>
  parseFloat(
    Math.round(number * 10 ** decimals).toFixed(decimals) / 10 ** decimals
  ).toFixed(decimals);

export const roundCrypto = (value) =>
  numberWithDecimals(value, CRYPTO_DECIMAL_PLACES).toString();

export const roundCryptoTerminal = (value) => {
  const parsedValue = parseFloat(value);

  if (value > 999999) return Math.round(parsedValue).toString();
  return parseFloat(parsedValue.toPrecision(CRYPTO_DECIMAL_PLACES)).toString();
};

export const roundFiat = (value) =>
  numberWithDecimals(value, FIAT_DECIMAL_PLACES).toString();

export function fiatValueWithCurrencySymbol(value, currency) {
  return `${value}${fiatSymbols(currency)}`;
}

export function fiatValueWithCurrency(value, currency) {
  return `${currency} ${value}`;
}

export function cryptoValueWithCurrency(value, currency) {
  return `${value} ${currency}`;
}

const getFiatCurrencyItems = (currencies) =>
  Object.keys(currencies).map((currency) => ({
    label: `${currency} ${fiat[currency]}`,
    value: currency,
  }));

const getSettlementCurrencyItems = (currencies) =>
  Object.entries(currencies).map(([currency, label]) => ({
    label,
    value: currency,
  }));

const getSettlementCryptoCurrencyItems = (currencies) =>
  Object.entries(currencies).flatMap(([currency, chains]) =>
    chains.map((chain) => ({
      label: `${currency} - ${cryptoChains[chain]} Network`,
      value: `${currency}-${chain}`,
    }))
  );

export const payoutFieldsForCurrency = (currency, bankCountry) => {
  if (isCrypto(currency)) return ['cryptoWalletAddress'];

  if (!bankCountry) return [];

  switch (currency) {
    case 'EUR':
    case 'RON':
    case 'CHF':
      return ['iban', 'swiftCode'];
    case 'USD':
      return bankCountry === 'USA'
        ? ['bankAccountNumber', 'routingNumber']
        : ['variableAccountTypeNumber', 'swiftCode'];
    case 'GBP':
      return bankCountry === 'GBR'
        ? ['bankAccountNumber', 'sortCode']
        : ['variableAccountTypeNumber', 'swiftCode'];
    default:
      return [];
  }
};

export const fiatCurrencyItems = getFiatCurrencyItems(fiat);
export const referenceFiatCurrencyItems = getFiatCurrencyItems(referenceFiat);
export const settlementFiatCurrencyItems =
  getSettlementCurrencyItems(settlementFiat);
export const settlementCryptoCurrencyItems =
  getSettlementCryptoCurrencyItems(settlementCrypto);
export const settlementCurrencyItems = [
  ...settlementFiatCurrencyItems,
  ...settlementCryptoCurrencyItems,
];
export const settlementCurrenciesReadOnlyItems = getSettlementCurrencyItems(
  settlementCurrenciesReadOnly
);
